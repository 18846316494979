import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import ProjectsNavigation from "../../components/projects-navigation"
import ProjectJourney from "../../components/project-journey"
import { content } from "../pages.module.scss"

export default function PageProjectJourney() {
    const currentLang = "en"
    return (
        <Layout lang={currentLang}>
            <Seo
                lang={currentLang}
                title="Project - Journey"
                keywords={[`powszuk`, `design`]}
            />
            <div className={content}>
                <ProjectJourney lang={currentLang} />
            </div>
            <ProjectsNavigation lang={currentLang} project="journey" />
        </Layout>
    )
}
